import moment from 'moment';
import _ from 'lodash';
import { CREATED_DATE_FORMAT } from '@vl/mod-utils/datetime';
import i18n from 'i18n-js';

export const formatter = {
  createdAtTxt: (ctx) => (itemData) => {
    const createdAt = _.get(itemData, 'member_profile.created_at', '');
    if (!createdAt) return '';
    return ctx.apply('i18n.t', 'Advisor.joinedSince', { dateStr: moment(createdAt).format('Do MMM, YYYY') });
  },
  profileId: () => (itemData) => {
    return _.get(itemData, 'member_profile.id', '');
  },

  fullName: () => (itemData) => {
    return _.get(itemData, 'member_profile.first_name', '') + ' ' + _.get(itemData, 'member_profile.last_name', '');
  },

  startDate: () => (itemData) => {
    const startAt = _.get(itemData, 'member_profile.created_at');
    if (!startAt) return '';
    const format_by_locale = _.get(CREATED_DATE_FORMAT, moment.locale(i18n.locale), 'vi');
    return `${moment(startAt)
      .locale(i18n.locale)
      .format(format_by_locale)}`;
  },

  profilePhoneNumber: () => (itemData) => {
    return _.get(itemData, 'member_profile.phone', '');
  },

  email: () => (itemData) => {
    return _.get(itemData, 'member_profile.email', '');
  },

  avatarUrl: (ctx) => (itemData) => {
    const displayName = formatter.fullName(ctx)(itemData);

    return (
      _.get(itemData, 'member_profile.avatar_url', `https://ui-avatars.com/api/?name=${displayName}`) ||
      `https://ui-avatars.com/api/?name=${displayName}`
    );
  },
};

export default formatter;
