import React from 'react';
import _ from 'lodash';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import { Link } from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';

export const HeaderNotification = () => (
  <DIV className="forUserOnly">
    <DIV className="component">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <li className="text-main hidden xl:flex justify-center items-center hover:text-white500">
            <Link
              className="text-white500"
              to={ctx.apply('routeStore.toUrl', 'toolAccountNotification')}
              target="_blank"
            >
              <div className="relative group-hover:text-white500">
                {gstyles.icons({
                  name: 'notification',
                  fill: gstyles.colors.sub,
                  size: 24,
                })}

                <DIV forceCtx>
                  {ctx.get('notificationCount') > 0 && (
                    <span className="group-hover:text-white500 absolute w-5 h-5 text-center font-semibold flex justify-center items-center -top-3 -right-3 text-xs bg-red500 text-white500 rounded-full">
                      {ctx.get('notificationCount') > 9 ? '9+' : ctx.get('notificationCount')}
                    </span>
                  )}
                </DIV>
              </div>
            </Link>
          </li>
        );
      })}
    </DIV>
  </DIV>
);

export default HeaderNotification;
