import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import AccountListSideBar from '@uz/unitz-tool-components/AccountListSideBar';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import SectionWrapper from '@uz/unitz-ctf-theme/components/SectionWrapper';
import gstyles from '@vl/gstyles';
import LayoutNavMenu from '@uz/unitz-layout-web/LayoutNavMenu';
import LangSwitchTransparent from '@uz/unitz-tool-components/LangSwitch';
import { getCurrentLocaleConfig } from '@uz/mod-translations/utils-biz';
import useRoute from '@vl/hooks/useGbRouteDe';
import { Drawer, Modal } from 'antd';
import Image from '@uz/unitz-components-web/Image';
import NavbarMenuProfile from '@uz/unitz-tool-components/NavbarMenuProfile';
import NavbarAuthSection from '@uz/unitz-tool-components/NavbarAuthSection';
import _ from 'lodash';

const MobileNavbar = () => {
  const [visible, setVisible] = React.useState(false);
  const [modalVisible, $modalVisible] = React.useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const openModal = () => {
    $modalVisible(true);
  };

  const closeModal = () => {
    $modalVisible(false);
  };

  const route = useRoute();
  const currentLocale = getCurrentLocaleConfig(route);
  const [lang, $lang] = React.useState(false);

  const MenuModal = () => {
    return (
      <DIV>
        <Modal
          visible={modalVisible}
          onCancel={closeModal}
          onClose={closeModal}
          wrapClassName={'my-modal'}
          className="rounded-lg overflow-hidden shadow-lg"
          maskStyle={{ background: gstyles.colors.black300 }}
          destroyOnClose
          centered
          width={688}
          closable={false}
          footer={false}
          afterClose={closeModal}
        >
          <div>
            {/* <LayoutNavMenu.POS name="nav-lang"> */}
            <div className="flex justify-between items-center p-4">
              <div className="flex items-center space-x-2" onClick={() => $lang(!lang)}>
                <span>
                  {gstyles.icons({
                    name: currentLocale.get('flag'),
                    size: 24,
                  })}
                </span>
                <span className="text-main">{ctx.apply('i18n.t', `SettingLanguage.${currentLocale.get('lang')}`)}</span>
                <span>
                  {gstyles.icons({
                    name: 'arrow-down3',
                    size: 24,
                  })}
                </span>
              </div>
            </div>
            {!!lang && <LangSwitchTransparent />}
            {/* </LayoutNavMenu.POS> */}

            {/* <LayoutNavMenu.POS name="nav-profile"> */}
            <NavbarAuthSection />
            {/* </LayoutNavMenu.POS> */}
            {/* 
            <LayoutNavMenu.POS name="nav-ctf-menu">
              <div className="flex flex-col items-start flex-1">{ctx.apply('ctf.renderSectionChild', 1)}</div>
            </LayoutNavMenu.POS> */}
          </div>
        </Modal>
      </DIV>
    );
  };

  return (
    <DIV>
      <Drawer
        placement="left"
        bodyStyle={{ padding: 10 }}
        closable={false}
        onClose={onClose}
        width={'60%'}
        visible={visible}
      >
        <div className="w-full">
          <AccountListSideBar />
        </div>
      </Drawer>
      <SectionWrapper className="h-16 relative bg-white500 shadow-lg" as="div">
        <MenuModal />
        <div className="flex flex-row h-16 px-4 app-row justify-between w-full">
          <div style={{ maxWidth: 150 }} className="flex items-center justify-between">
            <button className="focus:outline-none cursor-pointer mr-2" onClick={openModal}>
              {gstyles.icons({ name: 'menu', size: 24, fill: gstyles.colors.main })}
            </button>
            <div
              style={{ width: 250 }}
              onClick={showDrawer}
              className="cursor-pointer justify-between flex space-x-4 items-center"
            >
              <div className="flex space-x-4 items-center">
                <div className="flex items-center" style={{ width: 32 }}>
                  <Image
                    src={
                      _.get(route, 'params.account_profile.avatar_url') ||
                      'https://images.ctfassets.net/4cwknz5arvmg/353lGx5KBf0xu3V8AD6VYT/8556f0a211305bf23312f1b0543fd021/Logo_Unitz_OK_2.png?w=1100&q=50'
                    }
                    size="cover"
                    title="logo"
                    alt="logo_account"
                    preview={false}
                  />
                </div>
                {renderPropsComposer(
                  {
                    matcher: (props) =>
                      _.get(props, 'location.pathname') ===
                        `${lang}${_.get(props.getPageContext(), 'params.slug')}/create` &&
                      !!_.get(props.getPageContext(), 'params.accountId'),

                    render: () => (
                      <div className="text-main font-semibold text-xs">
                        {ctx.apply('i18n.t', 'ZoomToolOrganization.create')}
                      </div>
                    ),
                  },
                  {
                    matcher: (props) =>
                      _.get(props, 'location.pathname') === `${lang}accounts/me/create` &&
                      !_.get(props.getPageContext(), 'params.accountId'),

                    render: () => (
                      <div className="text-main font-semibold text-xs">
                        {ctx.apply('i18n.t', 'ZoomToolOrganization.create')}
                      </div>
                    ),
                  },
                  {
                    matcher: (props) => !_.get(props.getPageContext(), 'params.accountId'),
                    render: () => <div className="text-main font-semibold text-xs">Unitz</div>,
                  },
                  {
                    matcher: (props) => !!_.get(props.getPageContext(), 'params.accountId'),
                    render: (props) => (
                      <div className="flex flex-col">
                        <div className="text-main font-semibold text-xs">Unitz</div>
                        <div className="text-main font-semibold text-xs">
                          {_.truncate(_.get(props, 'params.account_profile.display_name', ''), { length: 15 })}
                        </div>
                      </div>
                    ),
                  },
                  () => null
                )(route)}
              </div>
            </div>
          </div>
          <NavbarMenuProfile />
        </div>
      </SectionWrapper>
    </DIV>
  );
};

export default MobileNavbar;
