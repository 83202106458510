import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import firebase from 'gatsby-plugin-firebase';
import useRoute from '@vl/hooks/useGbRouteDe';
import { getLocaleConfig, getLocaleConfigByLang } from '@uz/mod-translations/utils-biz';
import { useFormik } from 'formik';
import { notification } from 'antd';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            currentLanguage: hook((ctx) => {
              const route = useRoute();
              const pageContext = route.getPageContext();
              const lang = _.get(pageContext, 'lang', 'en');
              return lang;
            }),

            form: hook((ctx) => {
              const [activeId, $activeId] = React.useState('');
              const getLang = (item) => _.get(item, 'lang');
              const route = useRoute();

              const form = useFormik({
                initialValues: {
                  lang: '',
                },
                onSubmit: async (values, actions) => {
                  try {
                    const lang = getLang(values);
                    const localeConfig = getLocaleConfigByLang(lang);
                    $activeId(lang);
                    await ctx.apply('currentUserModel.updateLanguage', lang);
                    await localeConfig.setCurrent();
                    await notification['success']({
                      message: ctx.apply('i18n.t', 'Setting.Language.Notification.success'),
                      placement: 'bottomRight',
                    });
                    route.navigate(localeConfig.withLangPrefix());
                  } catch (error) {
                    notification['error']({
                      message: ctx.apply('i18n.t', 'Setting.Language.Notification.fail'),
                      placement: 'bottomRight',
                    });
                    console.log('err', error);
                  }
                },
              });

              return form;
            }),

            submitForm: hook((ctx) => {
              return {
                handleSubmit: async (values) => {
                  await ctx.apply('form.setFieldValue', 'lang', values);
                  await ctx.apply('form.submitForm');
                },
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
