import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import _ from 'lodash';
import { Image } from '@uz/unitz-components-web/Image';
import styled from 'styled-components';
import cx from 'classnames';
import { ctx } from '@vl/redata';
import useRoute from '@vl/hooks/useGbRouteDe';
import { Divider, Menu } from 'antd';
import gstyles from '@vl/gstyles';
import Link from '@uz/unitz-components-web/Link';
import ComponentCheckPoint from '@uz/unitz-tool-components/ComponentCheckPoint';
import { QoS } from '@vl/mod-utils/QoS';

const StyledImage = styled.div``;

const CustomMenu = styled.div`
  .ant-menu {
    background: transparent;
    border-right: 0;
  }
  .ant-menu-title-content {
    a {
      color: ${gstyles.colors.sub};
    }
  }
  .ant-menu > .ant-menu-item-selected,
  .ant-menu > .ant-menu-item-active {
    background-color: #e6ebf0;
    border-radius: 8px;
    .ant-menu-title-content {
      a {
        color: ${gstyles.colors.white500};
      }
    }
  }
  .ant-menu: not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${gstyles.colors.brandb500};
  }
`;

const Index = () => {
  // @TODO: validate missing required input
  const route = useRoute().getPageContext();

  return (
    <DIV className="component">
      <div className="h-full flex flex-col justify-between">
        <div>
          <div className="flex items-center space-x-4 px-4">
            <StyledImage className={cx(ctx.get('ctfData.className'))}>
              <Image
                src={
                  _.get(route, 'params.account_profile.avatar_url') ||
                  ctx.apply('ctf.findImage', ctx.get('ctfData'), 'image', '')
                }
                size="cover"
                title={ctx.get('ctfData.shortText')}
                alt={ctx.get('ctfData.shortText')}
                preview={false}
              />
            </StyledImage>
            {_.get(route, 'accountId') ? (
              <a href={ctx.apply('routeStore.toUrl', 'toolAccountDetail')}>
                <div className="flex flex-col">
                  <div className="text-main font-semibold text-xs">
                    {ctx.apply('i18n.t', 'ZoomToolOrganization.organization')}
                  </div>
                  <div className="text-main font-normal text-xs">
                    {_.get(route, 'params.account_profile.display_name', '')}
                  </div>
                </div>
              </a>
            ) : (
              <div className="text-main font-semibold text-sm">
                {ctx.apply('i18n.t', 'ZoomToolOrganization.create')}
              </div>
            )}
          </div>
          {!_.isEmpty(ctx.get('@items.menuData')) && (
            <>
              <Divider style={{ margin: '16px 0', borderColor: gstyles.colors.placeHolder }} />
              <div className="px-4 text-main font-semibold text-sm mb-3">
                {ctx.apply('i18n.t', 'ZoomToolOrganization.organization')}
              </div>
            </>
          )}
          <div>
            <CustomMenu>
              <Menu
                mode="inline"
                inlineIndent={24}
                defaultSelectedKeys={ctx.get('@items.activeAccount')}
                onSelect={(selectedItemObject) => {
                  ctx.apply('@items.onSelectMenu', selectedItemObject);
                }}
              >
                {_.map(ctx.get('@items.menuData'), (account, index) => (
                  <Menu.Item key={account.id}>
                    <Link external key={index} to={ctx.apply('routeStore.toUrl', 'toolAccountDetail', account)}>
                      <div className="py-3 text-sm">{_.get(account, 'account_profile.display_name', '')}</div>
                    </Link>
                  </Menu.Item>
                ))}
              </Menu>
            </CustomMenu>
            <Divider style={{ margin: '12px 0', borderColor: gstyles.colors.placeHolder }} />
            <ComponentCheckPoint
              render={[() => !ctx.get('authModel.currentUser.emailVerified'), () => !QoS.can('account.insert')]}
            >
              <Link to={ctx.apply('routeStore.toUrl', 'toolCreateAccount')}>
                <div className="py-2 px-4 flex items-center space-x-2">
                  <div>{gstyles.icons({ name: 'add-circle', size: 24, fill: gstyles.colors.sub })}</div>
                  <div className="text-sub text-sm">{ctx.apply('i18n.t', 'ZoomToolOrganization.create')}</div>
                </div>
              </Link>
            </ComponentCheckPoint>
          </div>
        </div>
        {!!ctx.apply('routeStore.toUrl', 'toolAccountEdit') && (
          <Link to={ctx.apply('routeStore.toUrl', 'toolAccountEdit')}>
            <div className="py-2 px-4 flex items-center space-x-2">
              <div>{gstyles.icons({ name: 'setting', size: 24, fill: gstyles.colors.sub })}</div>
              <div className="text-sub text-sm">{ctx.apply('i18n.t', 'ZoomToolOrganization.settings')}</div>
            </div>
          </Link>
        )}
      </div>
    </DIV>
  );
};

export default Index;
