import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Menu, Dropdown, Space } from 'antd';
import MemberAvatar from '@uz/unitz-tool-components/MemberAvatar';
import _ from 'lodash';
import ctxMemo from '@vl/redata/ctxMemo.macro';

import LinkComponent from '@uz/unitz-components-web/Link';
import Link from '@uz/unitz-ctf-theme/components/Link';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

const { SubMenu } = Menu;
const CustomDropDownContent = styled.div`
  border-radius: 8px;
  .ant-menu {
    border-radius: 8px;
    padding: 8px 0;
  }
`;

const CustomMenu = styled.div`
  .ant-menu {
    background: ${gstyles.colors.white500};
    border-right: none;
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        display: flex;
        height: auto;
        margin: 0;
        padding-left: 0 !important;
      }
    }
  }
`;

const CustomText = styled.span`
  span {
    color: ${gstyles.colors.main};
  }
`;

const UserProfileMenu = () => {
  const ref = React.useRef(null);
  return (
    <DIV className="component" forceCtx>
      {ctx.apply('ctf.renderProps', () => {
        const DropDownProfile = ctxMemo(() => (
          <li className="py-0 flex justify-center items-center" ref={ref}>
            <Dropdown
              className="flex"
              trigger="click"
              getPopupContainer={() => ref.current}
              overlay={
                <CustomDropDownContent className="shadow-xl">
                  <Menu>
                    {_.map(ctx.get('menuData', []), (itemData, index) => {
                      const to = ctx.apply('routeStore.toLocale', itemData.href);
                      if (!to) return null;
                      return (
                        <Menu.Item className="py-1 px-3 hover:bg-brandb-lightest" key={index}>
                          <LinkComponent
                            className="text-main hover:text-main"
                            to={ctx.apply('routeStore.toLocale', `${itemData.href}`)}
                          >
                            <div className="flex items-center space-x-2">
                              <div>
                                {gstyles.icons({
                                  name: itemData.icon,
                                  size: 20,
                                  fill: gstyles.colors.sub,
                                })}
                              </div>
                              <div>{itemData.text}</div>
                            </div>
                          </LinkComponent>
                        </Menu.Item>
                      );
                    })}
                    <Menu.Item
                      className="py-1 px-3 hover:bg-brandb-lightest"
                      onClick={() => ctx.apply('authModel.logout')}
                    >
                      <LinkComponent className="text-main hover:text-main" to={'#logout'}>
                        <div className="flex items-center space-x-2">
                          <div>
                            {gstyles.icons({
                              name: 'logout',
                              size: 20,
                              fill: gstyles.colors.sub,
                            })}
                          </div>
                          <div>{ctx.apply('i18n.t', 'Login.logout')}</div>
                        </div>
                      </LinkComponent>
                    </Menu.Item>
                  </Menu>
                </CustomDropDownContent>
              }
              placement="bottomRight"
            >
              <div className="mb-0 text-white cursor-pointer">
                <Space size="small">
                  <MemberAvatar
                    size={{ xs: 48, lg: 28 }}
                    alt="avatar"
                    className="mr-2"
                    user_id={ctx.apply('currentUserModel.getUserId')}
                    account_id={ctx.apply('accountModel.getAccountId')}
                    showName
                    textClassName="text-main ml-2 ml-0"
                  />
                  {/* <span className="text-main">{ctx.apply('currentUserModel.getDisplayName')}</span> */}

                  <span>{gstyles.icons({ name: 'arrow-down3', size: 24, fill: gstyles.colors.sub })}</span>
                </Space>
              </div>
            </Dropdown>
          </li>
        ));

        const MobileDropDownProfile = ctxMemo(() => (
          <li className="py-0 flex justify-center items-center" ref={ref}>
            <Dropdown
              className="flex"
              trigger="click"
              autoFocus
              overlayClassName="bg-background1"
              getPopupContainer={() => ref.current}
              overlay={
                <CustomDropDownContent className="shadow-xl">
                  <Menu>
                    {_.map(ctx.get('menuData', []), (itemData, index) => {
                      const to = ctx.apply('routeStore.toLocale', itemData.href);
                      if (!to) return null;
                      return (
                        <Menu.Item className="py-1 px-3 hover:bg-brandb-lightest" key={index}>
                          <LinkComponent
                            className="text-main hover:text-main"
                            to={ctx.apply('routeStore.toLocale', `${itemData.href}`)}
                          >
                            <div className="flex items-center space-x-2">
                              <div>
                                {gstyles.icons({
                                  name: itemData.icon,
                                  size: 20,
                                  fill: gstyles.colors.sub,
                                })}
                              </div>
                              <div>{itemData.text}</div>
                            </div>
                          </LinkComponent>
                        </Menu.Item>
                      );
                    })}
                    <Menu.Item
                      className="py-1 px-3 hover:bg-brandb-lightest"
                      onClick={() => ctx.apply('authModel.logout')}
                    >
                      <LinkComponent className="text-main hover:text-main" to={'#logout'}>
                        <div className="flex items-center space-x-2">
                          <div>
                            {gstyles.icons({
                              name: 'logout',
                              size: 20,
                              fill: gstyles.colors.sub,
                            })}
                          </div>
                          <div>{ctx.apply('i18n.t', 'Login.logout')}</div>
                        </div>
                      </LinkComponent>
                    </Menu.Item>
                  </Menu>
                </CustomDropDownContent>
              }
              placement="bottomRight"
            >
              <div className="mb-0 text-white cursor-pointer">
                <Space size="small">
                  <MemberAvatar
                    size={{ xs: 28, md: 28 }}
                    alt="avatar"
                    className="mr-2"
                    user_id={ctx.apply('currentUserModel.getUserId')}
                    account_id={ctx.apply('accountModel.getAccountId')}
                    showName
                    textClassName="text-main font-semibold text-xs ml-2 ml-0"
                  />
                  {/* <span className="text-main">{ctx.apply('currentUserModel.getDisplayName')}</span> */}

                  <span>{gstyles.icons({ name: 'arrow-down3', size: 24, fill: gstyles.colors.sub })}</span>
                </Space>
              </div>
            </Dropdown>
          </li>
        ));

        // const MenuProfile = ctxMemo(() => (
        //   <li className="py-3 flex justify-start items-start">
        //     <CustomMenu>
        //       <Menu expandIcon={<></>} mode="inline" className="flex flex-col space-y-2 cursor-pointer">
        //         <SubMenu
        //           title={
        //             <div className="text-white cursor-pointer">
        //               <Space size="small">
        //                 <MemberAvatar
        //                   size={{ xs: 28, md: 28, lg: 28 }}
        //                   alt="avatar"
        //                   className="mr-3"
        //                   user_id={ctx.apply('currentUserModel.getUserId')}
        //                   account_id={ctx.apply('accountModel.getAccountId')}
        //                   showName
        //                   textClassName="text-main font-semibold text-xs"
        //                 />
        //                 {/* <span className="text-main font-semibold text-base">
        //                   {ctx.apply('currentUserModel.getDisplayName')}
        //                 </span> */}
        //                 {gstyles.icons({ name: 'arrow-down3', size: 24, fill: gstyles.colors.sub })}
        //               </Space>
        //             </div>
        //           }
        //         >
        //           {_.map(ctx.get('sectionData.sections', []), (section, index) => {
        //             const menuItem = ctx.apply('ctf.findEntry', section);
        //             if (!menuItem) return null;
        //             return (
        //               <Menu.Item className="bg-white500" key={index}>
        //                 <CustomText>
        //                   <Link className="text-main" item={menuItem} />
        //                 </CustomText>
        //               </Menu.Item>
        //             );
        //           })}
        //         </SubMenu>
        //       </Menu>
        //     </CustomMenu>
        //   </li>
        // ));

        return (
          <DIV>
            {!!ctx.apply('currentUserModel.getUserId') && (
              <ResponsiveProps
                xs={{ renderer: () => <MobileDropDownProfile /> }}
                md={{ renderer: () => <MobileDropDownProfile /> }}
                lg={{ renderer: () => <DropDownProfile /> }}
                xl={{ renderer: () => <DropDownProfile /> }}
              >
                {(resProps) => !!resProps && resProps.renderer()}
              </ResponsiveProps>
            )}
          </DIV>
        );
      })}
    </DIV>
  );
};

export default displayName(UserProfileMenu);
